import request from "./request";

/**
 * 悬浮菜单
 */

/**
 * 查询
 */
export const get = () => request.get("/menu/");

/**
 * 编辑
 */
export const createOrUpdate = data => request.post("/menu/", data);
