<template>
  <Layout>
    <div class="app-float-menu">
      <div class="left">
        <a-affix :offset-top="24">
          <div class="frame">
            <div class="navbar">
              <img src="@/assets/menu/navbar.png" />
            </div>
            <div class="preview">
              <div v-if="form.enabled" class="float-bar">
                <div class="fixed">
                  <div
                    v-for="(item, index) in outItems"
                    :key="item.key"
                    :style="outItemStyle(item, index)"
                    class="touch-icon"
                  />
                </div>
                <div
                  v-if="foldItems.length > 0"
                  @click="previewModal = !previewModal"
                  class="handler"
                >
                  <div
                    class="defaultImg handler-icon"
                    :class="previewModal ? null : 'active'"
                  />
                  <div
                    class="openImg handler-icon"
                    :class="previewModal ? 'active' : null"
                  />
                  <div class="modal" :class="previewModal ? 'show' : null">
                    <div v-for="item in foldItems" :key="item.key" class="item">
                      <div class="touch-icon" :style="foldItemStyle(item)" />
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tabbar">
              <div class="tab">
                <img src="@/assets/menu/ic-home.png" />
                <div class="title active">首页</div>
              </div>
              <div class="tab">
                <img src="@/assets/menu/ic-category.png" />
                <div class="title">分类</div>
              </div>
              <div class="tab">
                <img src="@/assets/menu/ic-bag.png" />
                <div class="title">购物袋</div>
              </div>
              <div class="tab">
                <img src="@/assets/menu/ic-user.png" />
                <div class="title">我</div>
              </div>
            </div>
          </div>
        </a-affix>
      </div>

      <div class="right">
        <a-spin :spinning="spinning">
          <div class="tool">
            <a-form layout="inline">
              <a-button
                :disabled="!form.enabled"
                @click="dialogVisible = true"
                type="primary"
              >
                <a-icon type="file-add" /> 添加菜单
              </a-button>
            </a-form>

            <a-form layout="inline" style="text-align: center;">
              <div><a-switch v-model="form.enabled" /> 启用悬浮菜单</div>
              <div style="margin-top:8px;">
                应用于：
                <a-checkbox :disabled="!form.enabled" v-model="form.homeEnable"
                  >首页</a-checkbox
                >
                <a-checkbox
                  :disabled="!form.enabled"
                  v-model="form.categoryEnable"
                  >分类页</a-checkbox
                >
                <a-checkbox
                  :disabled="!form.enabled"
                  v-model="form.productListEnable"
                  >商品列表页</a-checkbox
                >
                <a-checkbox :disabled="!form.enabled" v-model="form.pageEnable"
                  >自定义页</a-checkbox
                >
              </div>
            </a-form>

            <a-form layout="inline">
              <a-button type="primary" @click="submit">
                保存
              </a-button>
            </a-form>
          </div>
          <table class="menu-items">
            <thead class="">
              <th class="sort">排序</th>
              <th class="icon">图标</th>
              <th class="name">类型</th>
              <th class="title">显示标题</th>
              <th class="out">独立</th>
              <th class="target">跳转</th>
              <th class="action">操作</th>
            </thead>
            <draggable
              :tag="'tbody'"
              v-model="form.json"
              handle=".drag-handler"
              @start="drag = true"
              @end="drag = false"
              v-bind="{
                animation: 200
              }"
            >
              <tr
                class="row"
                v-for="(item, index) in form.json"
                :key="item.key"
              >
                <td class="dragzone" style="text-align:center;">
                  <a-icon type="menu" class="drag-handler" />
                </td>
                <td>
                  <a-upload
                    :data="{ index }"
                    listType="picture-card"
                    :action="UPLOAD_URL"
                    :headers="{ t: getToken }"
                    withCredentials
                    accept="image/*"
                    :showUploadList="false"
                    @change="handleUpload"
                    :before-upload="beforeUpload"
                  >
                    <img v-if="item.icon" :src="item.icon" style="width:50px" />
                    <div v-else>
                      <a-icon type="plus" />
                      <div class="ant-upload-text">
                        上传
                      </div>
                    </div>
                  </a-upload>
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  <a-input
                    :disabled="!form.enabled"
                    v-model="item.title"
                    :max-length="4"
                  />
                </td>
                <td style="text-align:center;">
                  <a-checkbox
                    :disabled="
                      !form.enabled || (outItems.length >= 5 && !item.out)
                    "
                    v-model="item.out"
                  />
                </td>
                <td>
                  <!-- 商品 -->
                  <a-select
                    show-search
                    :filter-option="filterOption"
                    v-if="item.cls == 101"
                    v-model="item.productId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="p in productList"
                      :key="p.id"
                      :value="p.id"
                      >{{ p.title }}
                    </a-select-option>
                  </a-select>

                  <!-- 带货商品 -->
                  <a-select
                    show-search
                    :filter-option="filterOption"
                    v-if="item.cls == 106"
                    v-model="item.productId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="p in productList2"
                      :key="p.id"
                      :value="p.id"
                      >{{ p.title }}
                    </a-select-option>
                  </a-select>

                  <!-- 优惠券 -->
                  <a-select
                    v-if="item.cls == 102"
                    v-model="item.couponId"
                    placeholder="请选择"
                    notFoundContent="请在小商店后台创建优惠券"
                  >
                    <a-select-option
                      v-for="c in couponList"
                      :key="c.id"
                      :value="c.id"
                      >{{ c.name }}
                    </a-select-option>
                  </a-select>

                  <!-- 分类 -->
                  <a-tree-select
                    v-if="item.cls == 103"
                    v-model="item.categoryId"
                    style="width: 100%"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    :tree-data="categoryTree"
                    placeholder="请选择"
                    notFoundContent="请在小商店后台创建店铺分类"
                    tree-default-expand-all
                  >
                  </a-tree-select>

                  <!-- 页面 -->
                  <a-select
                    v-if="item.cls == 104"
                    v-model="item.pageId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="c in pageList"
                      :key="c.id"
                      :value="c.id"
                      >{{ c.name }}
                    </a-select-option>
                  </a-select>

                  <!-- 拼团 -->
                  <a-select
                    show-search
                    :filter-option="filterOption"
                    v-if="item.cls == 107"
                    v-model="item.pinId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="c in pinList"
                      :key="c.id"
                      :value="c.id"
                      >{{ c.title }}
                    </a-select-option>
                  </a-select>

                  <!-- 砍价 -->
                  <a-select
                    show-search
                    :filter-option="filterOption"
                    v-if="item.cls == 108"
                    v-model="item.kanId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="c in kanList"
                      :key="c.id"
                      :value="c.id"
                      >{{ c.title }}
                    </a-select-option>
                  </a-select>

                  <!-- 分享有礼 -->
                  <a-select
                    show-search
                    :filter-option="filterOption"
                    v-if="item.cls == 109"
                    v-model="item.shareId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="c in shareList"
                      :key="c.id"
                      :value="c.id"
                      >{{ c.title }}
                    </a-select-option>
                  </a-select>

                  <!-- 抽奖 -->
                  <a-select
                    show-search
                    :filter-option="filterOption"
                    v-if="item.cls == 114"
                    v-model="item.prizeId"
                    placeholder="请选择"
                  >
                    <a-select-option
                      v-for="c in prizeList"
                      :key="c.id"
                      :value="c.id"
                      >{{ c.title }}
                    </a-select-option>
                  </a-select>

                  <!-- 招募推荐官 -->
                  <a-input
                    v-if="item.cls == 111"
                    v-model="item.planId"
                    :max-length="10"
                    type="number"
                    placeholder="请复制招募计划 planId"
                  />

                  <!-- 小程序 -->
                  <a-input
                    v-if="item.cls == 115"
                    v-model="item.minapp"
                    :max-length="255"
                    placeholder="appid#query 或 #小程序链接"
                  />

                  <!-- 网页 -->
                  <a-input
                    v-if="item.cls == 112"
                    v-model="item.url"
                    :max-length="200"
                    placeholder="请输入网页地址"
                  />

                  <!-- 打电话 -->
                  <a-input
                    v-if="item.cls == 7"
                    v-model="item.tel"
                    :max-length="100"
                    placeholder="请输入电话号码"
                  />
                </td>
                <td>
                  <a-button
                    :disabled="!form.enabled"
                    type="danger"
                    @click="delRow(index)"
                  >
                    删除
                  </a-button>
                </td>
              </tr>
              <tr v-if="form.json.length <= 0">
                <td colspan="7">
                  <a-empty />
                </td>
              </tr>
            </draggable>
          </table>
        </a-spin>
      </div>

      <a-modal
        :visible="dialogVisible"
        :width="482"
        :footer="null"
        :closable="false"
        :mask-closable="true"
        @cancel="dialogVisible = false"
      >
        <div class="pop-add">
          <div
            class="item"
            @click="add(menuItem)"
            v-for="menuItem in MENU_ITEMS"
            :key="menuItem.cls"
          >
            <img
              :src="`https://store.xinsailei.com/menu/${menuItem.cls}.png`"
            />
            <div class="txt">{{ menuItem.name }}</div>
          </div>
        </div>
      </a-modal>
    </div>
  </Layout>
</template>

<script>
import draggable from "vuedraggable";

import Layout from "@/layout/index.vue";

import loadDataMixin from "@/mixins/loadData";

import uploadMixin from "@/mixins/upload";
import textMixin from "@/mixins/text";

import productMixin from "@/mixins/product";
import couponMixin from "@/mixins/coupon";
import categoryMixin from "@/mixins/category";
import pageMixin from "@/mixins/page";

const MenuSvc = require("@/service/menu");

import { randomKey } from "@/utils/tool";

//  菜单项
const MENU_ITEMS = [
  // 默认
  {
    cls: 1,
    name: "首页"
  },
  {
    cls: 2,
    name: "购物袋"
  },
  {
    cls: 3,
    name: "订单"
  },
  {
    cls: 4,
    name: "优惠券"
  },
  {
    cls: 7,
    name: "拨打电话"
  },
  {
    cls: 5,
    name: "分享"
  },
  {
    cls: 6,
    name: "直播"
  },
  {
    cls: 8,
    name: "回到顶部"
  },

  // 自定义
  {
    cls: 101,
    name: "自营商品"
  },
  {
    cls: 106,
    name: "带货商品"
  },
  {
    cls: 102,
    name: "单张券"
  },
  {
    cls: 103,
    name: "分类"
  },
  {
    cls: 104,
    name: "页面"
  },
  {
    cls: 107,
    name: "拼团"
  },
  {
    cls: 108,
    name: "砍价"
  },
  {
    cls: 114,
    name: "抽奖"
  },
  {
    cls: 109,
    name: "分享有礼"
  },
  {
    cls: 110,
    name: "会员中心"
  },
  // {
  //   cls: 111,
  //   name: "招推荐官"
  // },
  {
    cls: 113,
    name: "群裂变"
  },
  {
    cls: 115,
    name: "小程序"
  }

  // {
  //   cls: 112,
  //   name: "网页"
  // }
];

export default {
  name: "Menu",
  data() {
    return {
      MENU_ITEMS,

      drag: false,
      dialogVisible: false,
      spinning: false,

      previewModal: false,

      form: {
        enabled: false,
        homeEnable: false,
        categoryEnable: false,
        productListEnable: false,
        pageEnable: false,
        json: []
      }
    };
  },

  computed: {
    foldItems() {
      return this.form.json.filter(x => !x.out);
    },
    outItems() {
      return this.form.json.filter(x => x.out);
    },
    outItemCnt() {
      return this.outItems.length;
    }
  },

  mixins: [
    loadDataMixin,

    uploadMixin,
    textMixin,

    productMixin,
    couponMixin,
    categoryMixin,
    pageMixin
  ],

  methods: {
    foldItemStyle(item) {
      return {
        backgroundImage: `url(${item.icon})`
      };
    },
    outItemStyle(item, index) {
      const top = -60 * (this.outItemCnt - index);
      return {
        transform: `translateY(${top}px)`,
        backgroundImage: `url(${item.icon})`
      };
    },

    add(menuItem) {
      // 最多12个弹窗按钮
      if (this.foldItems.length >= 12) {
        this.$message.info("最多添加12个图标");
        return;
      }

      this.form.json.push({
        ...menuItem,

        key: randomKey(),
        title: menuItem.name,
        icon: `https://store.xinsailei.com/menu/${menuItem.cls}.png`,
        out: false
      });

      this.dialogVisible = false;
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url, index } = data;
        this.form.json[index].icon = url;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    },

    delRow(index) {
      this.form.json.splice(index, 1);
    },

    async submit() {
      let {
        enabled,
        homeEnable,
        categoryEnable,
        productListEnable,
        pageEnable,
        json
      } = this.form;
      json = JSON.stringify(json || []);

      try {
        let val = {
          enabled,
          homeEnable,
          categoryEnable,
          productListEnable,
          pageEnable,
          json
        };

        await MenuSvc.createOrUpdate(val);
        this.$message.info("保存成功");
      } catch (err) {
        console.error(err);
        this.$message.error("创建悬浮菜单失败，错误：" + err.message);
      }
    }
  },

  async mounted() {
    try {
      this.spinning = true;

      let res = await MenuSvc.get();
      let json = JSON.parse(res.json || "[]");

      // int to boolean
      json = json.map(x => ({ ...x, out: !!x.out }));

      this.form = {
        enabled: !!res.enabled,
        homeEnable: !!res.homeEnable,
        categoryEnable: !!res.categoryEnable,
        productListEnable: !!res.productListEnable,
        pageEnable: !!res.pageEnable,
        json
      };

      this.spinning = false;
    } catch (err) {
      console.error(err);
      this.$message.error("加载悬浮菜单失败，错误：" + err.message);

      this.spinning = false;
    }
  },

  components: {
    Layout,
    draggable
  }
};
</script>

<style lang="scss">
.app-float-menu {
  display: flex;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;

  .right {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 0;
    flex: 1;
  }

  .left {
    margin: 24px 0 24px 24px;
    width: 375px;
  }

  .frame {
    box-shadow: 0 0 10px #cecece;
    width: 375px;
    height: 80vh;
    max-height: 640px;
    border: 1px solid #f0f0f0;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .navbar {
      img {
        width: 100%;
        height: 90px;
      }
    }

    .preview {
      position: relative;
      overflow: hidden;
      flex: 1;

      .touch-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        cursor: pointer;
      }

      .float-bar {
        position: absolute;
        right: 15px;
        bottom: 15px;
        width: 50px;
      }

      .fixed {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .touch-icon {
          margin-bottom: 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

          position: absolute;
          left: 0;
          top: 0;

          transition: all 0.3s ease;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50%;
        }
      }

      .handler {
        width: 50px;
        height: 50px;
        position: relative;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        border-radius: 50%;
        cursor: pointer;

        .handler-icon {
          transition: all 0.3s ease;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 50%;
          overflow: hidden;

          &.active {
            transform: rotate(0deg);
            opacity: 1;
          }
        }

        .defaultImg {
          background: url(https://store.xinsailei.com/menu/ic-plus.png)
            no-repeat 50% / cover;
          transform: rotate(90deg);
          opacity: 0;
        }

        .openImg {
          background: url(https://store.xinsailei.com/menu/ic-close.png)
            no-repeat 50% / cover;
          transform: rotate(-90deg);
          opacity: 0;
        }
      }

      .modal {
        position: absolute;
        width: 250px;
        height: auto;
        left: -224px;
        bottom: 24px;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 5px;
        border-radius: 32px;

        transform-origin: bottom right;
        transform: scale(0.1);
        transition: all 0.3s ease;
        opacity: 0;

        display: flex;
        flex-wrap: wrap;

        &.show {
          opacity: 1;
          transform: scale(1) translateX(-20px) translateY(-20px);
        }

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 10px 15px;
          color: #fff;
          font-size: 12px;
          line-height: 1.5;

          .touch-icon {
            margin-bottom: 5px;
          }
        }
      }
    }

    .tabbar {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 60px;
      background-color: #fff;

      .tab {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          display: block;
          width: 27px;
          height: 27px;
          margin-bottom: 5px;
        }

        .title {
          font-size: 12px;
          color: #000;

          &.active {
            color: #ff6146;
          }
        }
      }
    }
  }

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 50px;
    height: 50px;
    margin: 0;
  }

  .menu-items {
    width: 100%;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 24px;

    .dragzone {
      .drag-handler {
        transform: scale(2);
        cursor: pointer;
      }
    }

    tr {
      background: #fff;
      transition: background 0.3s;

      &:hover {
        background: #e6f7ff;
      }
    }

    th {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      text-align: left;
      background: #fafafa;
      border-bottom: 1px solid #e8e8e8;
      transition: background 0.3s ease;
      white-space: nowrap;
      height: 53px;
    }

    th,
    td {
      padding: 16px;
      transition: background 0.3s;
      border-bottom: 1px solid #e8e8e8;
      white-space: nowrap;

      .ant-select {
        width: 100%;
        max-width: 300px;
      }
    }

    .sort {
      width: 62px;
    }
    .icon {
      width: 84px;
    }
    .name {
      width: 100px;
    }
    .title {
      width: 120px;
    }
    .out {
      width: 60px;
    }

    .target {
      flex: 1;
    }
    .action {
      width: 60px;
    }
  }
}

.pop-add {
  width: 440px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  .item {
    width: 100px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #eee;
    margin: 0 10px 10px 0;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background: #eee;
    }

    img {
      width: 50px;
      height: 50px;
      margin-bottom: 5px;
      border-radius: 50%;
    }

    .txt {
      font-size: 12px;
    }
  }
}
</style>
